import Vue from "vue";

export default {
    getAll: async () => {
        const { data } = await Vue.axios.get(`clientFundsAccounts`);
        return data;
    },

    getAllInvoices: async (pageNumber, pageSize, clientId, search, currency, dateFrom, dateTo) => {
        const response = await Vue.axios.get(`invoices`, {
            params: {
                pageNumber,
                pageSize,
                clientId,
                search,
                currency,
                startDate: dateFrom,
                endDate: dateTo,
            },
        });
        return response;
    },

    getAllBankFiles: async status => {
        return await Vue.axios.get(`customerPayments`, {
            params: {
                status,
            },
        });
    },

    getPaymentMatchedInvoices: async id => {
        const { data } = await Vue.axios.get(`customerPayments/` + id + "/invoices");
        return data;
    },

    getPaymentDetails: async id => {
        const { data } = await Vue.axios.get(`customerPayments/` + id);
        return data;
    },

    getInvoiceDetails: async (paymentId, invoiceId) => {
        const { data } = await Vue.axios.get(`customerPayments/${paymentId}/invoices/${invoiceId}`);
        return data;
    },

    matchInvoice: async (paymentId, invoiceId, invoiceData) => {
        const { data } = await Vue.axios.post(`customerPayments/${paymentId}/invoices/${invoiceId}`, invoiceData);
        return data;
    },
    // matchInvoice: async (paymentId, invoiceId, reminderFee, interestAmount, paymentAmount, debtAmount, bankAmount) => {
    //     const { data } = await Vue.axios.post(`customerPayments/${paymentId}/invoices/${invoiceId}`, {
    //         reminderFee: reminderFee,
    //         interestAmount: interestAmount,
    //         invoicePayment: paymentAmount,
    //         debtAmount: debtAmount,
    //         bankAmount: bankAmount,
    //         totalReceivable: reminderFee + interestAmount + paymentAmount + debtAmount,
    //     });
    //     return data;
    // },

    removeMatchingFromInvoice: async (paymentId, invoiceId) => {
        await Vue.axios.delete(`customerPayments/${paymentId}/invoices/${invoiceId}`);
    },

    getClients: async () => {
        const response = await Vue.axios.get(`invoices/clients`);
        return response.data;
    },

    markAsUnidentified: async id => {
        await Vue.axios.put(`customerPayments/${id}/makeUnidentified`);
    },

    clearAsUnidentified: async id => {
        await Vue.axios.put(`customerPayments/${id}/clearUnidentified`);
    },

    addNewComment: async (id, text) => {
        const { data } = await Vue.axios.post(`comments`, {
            commentSource: 2,
            sourceId: id,
            text: text,
        });
        return data;
    },

    createPainFile: async ids => {
        await Vue.axios.post("customerPayments/pain", {
            ids,
        });
    },

    getStatusCount: async () => {
        const { data } = await Vue.axios.get(`customerPayments/count`);
        return data;
    },
};
