<template>
    <div>
        <ModalEditPayment :visible="modalEditPayment" @close="modalEditPayment = false" @refresh="refreshData" :payment-id="selectedPaymentId" :invoice-id="selectedInvoiceId" />
        <ModalUnidentifyConfirmation :visible="modalUnidentifyConfirmation" @close="modalUnidentifyConfirmation = false" @refresh="refreshData" :payment-id="selectedPaymentId" />

        <div class="relative mb-2">
            <div class="relative grid grid-cols-2 gap-3 z-0">
                <div id="affix-content">
                    <AvstamningBankFiles
                        @paymentSelected="changeSelectedPayment"
                        @markAsUnidentified="openUnidentifyConfirmationModal"
                        @clearAsUnidentified="clearAsUnidentified"
                        :refresh-counter="refreshCounter"
                    />
                </div>
                <div class="relative">
                    <affix relative-element-selector="#affix-content" :offset="{ top: 10, bottom: 10 }">
                        <AvstamningFoundInvoices :payment-id="selectedPaymentId" @showEditInvoiceModal="openEditInvoiceModal" :refresh-counter="refreshCounter" />
                    </affix>
                </div>
            </div>
            <div class="sticky w-full flex justify-center bottom-0 z-10 -mt-10">
                <p @click="scrollToTheBottom" class="flex justify-center items-center w-12 h-8 rounded-tl-lg rounded-tr-lg text-white bg-company-blue cursor-pointer">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 14l-7 7m0 0l-7-7m7 7V3" />
                    </svg>
                </p>
            </div>
        </div>
        <AvstamningAllInvoices @openModalMatchInvoice="openEditInvoiceModal" :disabled="!selectedPaymentId" />
    </div>
</template>
<script>
import queryString from "query-string";
import Vue from "vue";
import Affix from "vue-affix";
import Api from "./avstamning.api";

Vue.use(Affix);

export default {
    components: {
        AvstamningFoundInvoices: () => import(/* webpackChunkName: "AvstamningFoundInvoices" */ "./components/AvstamningFoundInvoices.vue"),
        AvstamningBankFiles: () => import(/* webpackChunkName: "AvstamningBankFiles" */ "./components/AvstamningPayments.vue"),
        AvstamningAllInvoices: () => import(/* webpackChunkName: "AvstamningAllInvoices" */ "./components/AvstamningAllInvoices.vue"),
        ModalEditPayment: () => import("./modals/ModalEditPayment.vue"),
        ModalUnidentifyConfirmation: () => import("./modals/ModalUnidentifyConfirmation.vue"),
    },

    data() {
        return {
            avstamningData: [],
            selectedPaymentId: null,
            selectedInvoiceId: null,
            modalEditPayment: false,
            modalUnidentifyConfirmation: false,
            refreshCounter: 0,
        };
    },

    beforeCreate() {
        this.$store.commit("setTopbarTitle", "Avstämning");
        document.title = "Avstämning";
    },

    created() {
        const { selectedPayment } = queryString.parse(window.location.search, { sort: false });
        setTimeout(() => {
            this.selectedPaymentId = parseInt(selectedPayment);
        }, 500);
    },

    methods: {
        getAll() {},
        refreshData() {
            this.refreshCounter += 1;
            this.selectedPaymentId = null;
        },

        changeSelectedPayment(id) {
            this.selectedPaymentId = id;
        },

        openEditInvoiceModal(id) {
            this.selectedInvoiceId = id;
            this.modalEditPayment = true;
        },

        openUnidentifyConfirmationModal(id) {
            this.selectedPaymentId = id;
            this.modalUnidentifyConfirmation = true;
        },

        async clearAsUnidentified(id) {
            this.$waiting.start("processing");
            await Api.clearAsUnidentified(id);
            this.$notify.success({ title: "Utfört" });
            this.$waiting.end("processing");
            this.refreshData();
        },
        scrollToTheBottom() {
            window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: "smooth" });
        },
    },
};
</script>
